<template>
  <CRow>
    <CModal
      :show.sync="dangerModal"
      color="danger"
      title="ออกจากระบบ"
      centered
      :footer="footer"
    >
      <h4 class="text-center">คุณต้องการออกจากระบบใช่หรือไม่?</h4>
      <br />
      <h5 class="text-center">กรุณากดตกลงเพื่อออกจากระบบ</h5>
      <template #footer-wrapper>
        <CRow class="justify-content-center">
          <CCol col="4" v-if="loadingButton === true">
            <CButton block color="danger" @click="logout">ตกลง</CButton>
          </CCol>
          <CCol col="4" v-else-if="loadingButton === false">
            <CButton block color="danger" disabled>
              <CSpinner color="white" size="sm" />
            </CButton>
          </CCol>
          <CCol col="4">
            <CButton block color="dark" @click="$router.go(-1)">
              ยกเลิก
            </CButton>
          </CCol>
        </CRow>
        <br />
        <br />
      </template>
    </CModal>
  </CRow>
</template>

<script>
import firebase from 'firebase/app'
import 'firebase/auth'

export default {
  data() {
    return {
      dangerModal: true,
      footer: '',
      loadingButton: true,
    }
  },
  methods: {
    logout() {
      this.loadingButton = false
      firebase
        .auth()
        .signOut()
        .then(function() {
          // Sign-out successful.
          localStorage.removeItem('idToken')
          localStorage.removeItem('token')
          localStorage.removeItem('uid')
          localStorage.removeItem('shopObjectId')
          localStorage.removeItem('loginwith')
          window.location.href = '/login'
        })
        .catch(function(error) {
          console.log(error)
        })
    },
  },
}
</script>
